import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";
import PageLoader from "../components/page-loader";

const ApproachPage = () => {
    return (
        <div className="about-page">
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    <h5>Education philosophy</h5>
                    <h2>OUR APPROACH TO EDUCATION</h2>
                </div>
            </div>
            <div>
                <div className="container body-content">
                    <div>
                        <h3>OUR SCHOOL HAS 3 DISTINCT EXPRESSIONS</h3>
                        <p><h4>A model secondary school:</h4>We're constructing a small school
                            with a maximum capacity for 120 students. This facility will serve as
                            our experimental ground for educational research and innovative
                            approaches, intended for replication in other educational institutions. However, our
                            primary focus is to establish an elite institution that nurtures individuals from
                            low-income families into future leaders capable of guiding world-class
                            companies and even nations.</p>
                        <p><h4>A community centre:</h4>We are deeply committed to our immediate community in Agodo-Egbe, a small town with a significant young
                            population. Our goal is to create a community centre equipped with essential amenities like a library, sports
                            centre, STEM facility, conference space, and more. Our fundamental question revolves around inspiring the
                            community to envision and strive for a future beyond the current scope of possibilities within the community.</p>
                        <p><h4>An educational technology hub:</h4>We aim to share our successful in-house technological solutions and methodologies with the public. Additionally,
                            our commitment extends to conducting research and developing digital content focused on core academics,
                            leadership courses, and teacher training. These efforts are geared towards benefiting stakeholders in the
                            African educational sphere.</p>
                        <div>
                            <h5>OUR APPROACH TO BUILDING A MODEL SCHOOL</h5>
                            <p>With the model school, our primary focus is leveraging technology to enhance the delivery of core academic
                                subjects, ensuring effective learning that yields commendable academic outcomes tailored to each student's
                                abilities. This we hope would help us create time to focus on other aspects than core academics.</p>
                            <p>Beyond academics, we foster an environment that nurtures confidence, encourages self-expression, and introduces
                                unconventional career paths uncommon in the Nigerian context. We instil in our students a commitment to
                                continuous improvement, emphasising character development and cultivating habits essential for success.
                                Moreover, we empower them to identify community issues and devise solutions.</p>
                            <p>Beyond academics, we foster an environment that nurtures confidence, encourages self-expression, and introduces
                                unconventional career paths uncommon in the Nigerian context. We instil in our students a commitment to
                                continuous improvement, emphasising character development and cultivating habits essential for success.
                                Moreover, we empower them to identify community issues and devise solutions.</p>
                            <p>Our approach involves immersive experiences such as field trips, event volunteering, organising their own
                                initiatives, and a 'Friday two minutes of fame.' that provides an opportunity for them to work on their talents
                                and practise public speaking</p>
                            <p>We also prioritise teaching essential tech skills, assigning projects, and fostering a culture that values 
                                self-expression while instilling discipline and encouraging responsible decision-making.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ApproachPage;