import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";
import PageLoader from "../components/page-loader";

const AboutPage = () => {
    return (
        <div className="about-page">
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    <h5>WHO WE ARE</h5>
                    <h2>OUR STORY</h2>
                </div>
            </div>
            <div>
                <div className="container body-content">
                    <div>
                        <h3>THE JOURNEY OF STARTING IMS</h3>
                        <p>I come from a family deeply involved in education—five out of seven of us are in the field, and four are
                            professional teachers. My mum still runs a primary school she founded in 1994. My own journey into the education
                            space began after the mandated National Youth Service Corp in Nigeria.</p>
                        <p>In 2015, a friend and I toured a handful of secondary schools, speaking on 'Be the best you can be.' We were so
                            inspired by the students’ response that we decided to start a tutorial centre. Later, I left my friend to start
                            another tutorial centre before venturing into edtech, backed by a grant from the World Bank.</p>
                        <p>The COVID-19 pandemic of 2020 disrupted our plans for a blended school, leading to the closure of our company.
                            With traditional schooling halted, my sister and I started a free Whatsapp school. This endeavour presented a
                            significant opportunity—one of the biggest churches in Nigeria invited me to set up a Telegram school that
                            reached thousands across four African countries. To be able to serve these thousands of students, I had to
                            create hundreds of Khan-style maths tutorial videos.</p>
                        <p>These experiences culminated in the establishment of IMS post-pandemic. Our goal isn’t to merely be just another
                            secondary school; we aim to pioneer a model institution that challenges the norms of traditional education to
                            better equip students for the real world. The deliberate choice of the term 'model school' reflects our
                            intention to create a globally replicable educational system.</p>
                        <p>The model school represents just a fraction of our broader vision, aimed at making a positive impact on our
                            local community and the African continent as a whole. My life purpose is clear; I was born to nurture unassuming
                            youths into future leaders capable of steering world-class organisations or even nations.</p>
                        <div>
                            <h4>Regards, Dayo Moyo.</h4>
                            <p>Founder, IMS</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutPage;