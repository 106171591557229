import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";
import PageLoader from "../components/page-loader";

const BlogPage = () => {
    return (
        <div>
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    {/* <h5>Excursions and events</h5> */}
                    <h2>Our Blog Stories</h2>
                </div>
            </div>
            <div>
                <div className="feature-display blog-showcase mt_5">
                    <div className="contain">
                        <div className="grid-4">
                        </div>
                    </div>
                </div>
            </div>
            <Footer margin={true} />
        </div>
    )
}

export default BlogPage;