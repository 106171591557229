import "../assets/css/home.css";
import React, { useLayoutEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { Collapse } from "antd";
import { gsap, Power3 } from "gsap";

import Nav from "../components/nav";
import Footer from "../components/footer";

import Logo from "../assets/images/logo.png";

import { ReactComponent as PlusIcon } from "../assets/images/icons/plus.svg";
import { ReactComponent as LongArrowDown } from "../assets/images/icons/long-arrow-down.svg";
import { ReactComponent as LongArrowDownWhite } from "../assets/images/icons/long-arrow-down-white.svg";

import RealImgGrid1 from "../assets/images/home/real_grid_1.jpg";
import RealImgGrid2 from "../assets/images/home/real_grid_2.jpg";
import RealImgGrid3 from "../assets/images/home/real_grid_3.jpg";
import RealImgGrid4 from "../assets/images/home/real_grid_4.jpg";

import GridImg1 from "../assets/images/home/grid1.jpg";
import GridImg2 from "../assets/images/home/grid2.jpg";

import BookIllustration from "../assets/images/illustrations/8_clouds.png";
import StarIllustration from "../assets/images/illustrations/6_stars.png";

import Passport1 from "../assets/images/home/passport1.jpg";
import Passport2 from "../assets/images/home/passport2.jpg";
import Passport3 from "../assets/images/home/passport3.jpg";
import Passport4 from "../assets/images/home/passport4.jpg";

import { ReactComponent as HexagonIcon } from "../assets/images/icons/hexagon-cropped.svg";
import ManSmiling from "../assets/images/home/ev.webp";


const Home = () => {

    const { Panel } = Collapse;

    // const loader_1 = useRef();
    // const loader_2 = useRef();
    // const loader_3 = useRef();
    // const loader_4 = useRef();
    // const loader_5 = useRef();

    // const pageCover = useRef();

    // const [currentLoadState, setCurrentLoadState] = useState(0);

    // useLayoutEffect(() => {
    //     gsap.timeline()
    //         .to(loader_1.current, {
    //             width: "100%",
    //             ease: Power3.easeInOut,
    //             duration: 2,
    //             onComplete: () => setCurrentLoadState(20)
    //         })
    //         .to(loader_2.current, {
    //             width: "100%",
    //             ease: Power3.easeInOut,
    //             duration: 2,
    //             onComplete: () => setCurrentLoadState(40)
    //         })
    //         .to(loader_3.current, {
    //             width: "100%",
    //             ease: Power3.easeInOut,
    //             duration: 2,
    //             onComplete: () => setCurrentLoadState(60)
    //         })
    //         .to(loader_4.current, {
    //             width: "100%",
    //             ease: Power3.easeInOut,
    //             duration: 2,
    //             onComplete: () => setCurrentLoadState(80)
    //         })
    //         .to(loader_5.current, {
    //             width: "100%",
    //             ease: Power3.easeInOut,
    //             duration: 2,
    //             onComplete: () => setCurrentLoadState(100)
    //         })
    //         .to(pageCover.current, {
    //             borderBottomLeftRadius: "300%",
    //             borderBottomRightRadius: "300%",
    //             height: 0,
    //             duration: 3,
    //             ease: Power3.easeInOut,
    //             yoyo: true
    //         })
    // }, [])

    return (
        <div className="homepage-display">
            <Nav />
            <div className="hero-sect">
                <video muted autoPlay loop>
                    <source src="https://api.mdx.ac.ae/uploads/mdx_banner_1_daad3346d8.mp4" />
                </video>
                <div className="hero-sect-text">
                    <h2 className="desktop-only">Elevate Learning, Embrace Excellence: <br />Welcome to Isador Model Schools</h2>
                    <h2 className="mobile-only">Elevate Learning, Embrace Excellence: Welcome to Isador Model Schools</h2>
                </div>
                <div className="black-overlay"></div>
            </div>
            <div className="school-props school-hero">
                <div className="school-hero-content">
                    <div className="school-hero-main-content">
                        <div className="contain">
                            <div className="school-props-inner-content">
                                <h2 className="desktop-only">Express Love, Embrace Excellence: <br />Welcome to Isador Model School</h2>
                                <h2 className="mobile-only">Express Love, Embrace Excellence: Welcome to Isador Model School</h2>
                                <p>IMS is more than a place of academics; we're dedicated to preparing young minds—both within and beyond
                                    our school—for success in the real world.</p>
                                <div className="btn-flex">
                                    <button className="btn-black">Reach out to us</button>
                                    <button className="btn-grey">See how we work</button>
                                </div>
                            </div>
                            <div className="school-props-inner-images">
                                <div className="grid-4">
                                    <div className="props-img _1">
                                        <img src={RealImgGrid1} alt="school dropoff" />
                                    </div>
                                    <div className="props-img _2">
                                        <img src={RealImgGrid2} alt="school dropoff" />
                                    </div>
                                    <div className="props-img _3">
                                        <img src={RealImgGrid3} alt="school dropoff" />
                                    </div>
                                    <div className="props-img _4">
                                        <img src={RealImgGrid4} alt="school dropoff" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-bg">

                    </div>
                </div>
            </div>
            <div className="story-props first-block mt_5">
                <div className="contain">
                    <div className="grid-2">
                        <div>
                            <h5 className="desktop-only">Welcome to IMS<br /> Where world-class leaders are nurtured!</h5>
                            <h5 className="mobile-only">Welcome to IMS where world-class leaders are nurtured!</h5>
                            <p className="first-paragraph">
                                At IMS, we're not just building a school for a select few; we're creating an educational hub
                                that serves our entire community and the African continent. Our goal is to ensure that education
                                becomes the ultimate equaliser.
                            </p>
                            <p>Our work, as a social enterprise, centres on these pillars: constructing a model school/community
                                centre, fostering professional development/teacher training programs, conducting comprehensive educational
                                research, and crafting curriculum while creating digital educational and leadership content.</p>
                            <Link to="">Learn more</Link>
                        </div>
                        <div>
                            <div className="props-rect">
                                <img src={GridImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="story-props second-block">
                <div className="contain">
                    <div className="grid-2">
                        <div>
                            <div className="props-rect">
                                <img src={GridImg2} alt="" />
                            </div>
                        </div>
                        <div>
                            <h5 className="desktop-only">Why Support Our Work?<br /> The Big Problems We’re Solving!</h5>
                            <h5 className="mobile-only">Why Support Our Work? The Big Problems We’re Solving!</h5>
                            <p className="first-paragraph">
                                African students often lack access to resources that could place them on par with their global
                                counterparts. They have little or no knowledge of  the emerging career opportunities and
                                the skills required to pursue them.
                            </p>
                            <p>On the other hand, our educators are frequently undertrained in modern teaching tools and
                                methodologies. This gap is further compounded by educational research that tends to overlook
                                the unique challenges within the continent.</p>
                            <Link to="">REQUEST A CALL</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="school-feature">
                <div className="contain">
                    <div>
                        <p>Donate today and help us achieve our mission of preparing</p>
                        <h2>100,000<br /> African students</h2>
                        <p>For the real world in ten years.</p>
                        <button>Donate today</button>
                    </div>
                    <div className="img-disp left _2">
                        <img src={Passport1} alt="" />
                    </div>
                    <div className="img-disp left _3">
                        <img src={Passport2} alt="" />
                    </div>
                    <div className="img-disp right _2">
                        <img src={Passport3} alt="" />
                    </div>
                    <div className="img-disp right _3">
                        <img src={Passport4} alt="" />
                    </div>
                </div>
            </div>
            <div className="center-message red-bg">
                <div className="center-message-content">
                    <h5>WHAT WE MEAN BY</h5>
                    <LongArrowDownWhite />
                    <h4>At IMS, we are on a mission of preparing 100,000 African students for the real world in ten years</h4>
                    <p>In recent years, a gap has emerged between classroom learning and real-world application post-graduation. Our aim
                        is to empower young individuals with essential skills—leadership, critical thinking, social and emotional competencies — through
                        courses, training, and our facilities.</p>
                    <Link to="">Read More</Link>
                </div>
                <div className="right-illustration">
                    <img src={BookIllustration} alt="book illustration" />
                </div>
                <div className="left-illustration">
                    <img src={StarIllustration} alt="book illustration" />
                </div>
            </div>
            <div className="sec-faq mt_5">
                <div className="contain">
                    <div className="grid-2">
                        <div className="content-sect">
                            <h2>FAQs</h2>
                            <p>Have any other questions? Email us at <a href="mailTo:info@isadormodelschools.com"> info@isadormodelschools.com</a></p>
                            <button>Ask a question</button>
                        </div>
                        <div>
                            <div className="collapse-div">
                                <Collapse expandIconPosition="right"
                                    expandIcon={() => <PlusIcon />} ghost>
                                    <Panel key={1} header={<p><span>01.</span>Is the school free?</p>}>
                                        <p>We operate as a social enterprise, charging tuition fees to cover our costs. Our fees are subsidised
                                            in comparison to the exceptional quality of education we deliver.</p>
                                    </Panel>
                                    <Panel key={2} header={<p><span>02.</span>Where is the school located?</p>}>
                                        <p>Our model school is based in Lagos, Nigeria. However, our initiatives are dedicated to
                                            serving the broader African continent, with a particular focus on West Africa.</p>
                                    </Panel>
                                    <Panel className="last-panel" key={3} header={<p><span>03.</span>What costs do donations cover?</p>}>
                                        <p>The tuition paid by attending students covers our operational expenses. Donations are vital for funding capital
                                            projects and initiatives that impact young individuals who are not part of our school community.</p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;