import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";


// TedX
import TedX_1 from "../assets/images/excursions/tedx/_1.jpg";
import TedX_2 from "../assets/images/excursions/tedx/_2.jpg";
import TedX_3 from "../assets/images/excursions/tedx/_3.jpg";
import TedX_4 from "../assets/images/excursions/tedx/_4.jpg";
import TedX_5 from "../assets/images/excursions/tedx/_5.jpg";
import TedX_6 from "../assets/images/excursions/tedx/_6.jpg";
import TedX_7 from "../assets/images/excursions/tedx/_7.jpg";
import TedX_8 from "../assets/images/excursions/tedx/_8.jpg";

// The Platform
import Platform_1 from "../assets/images/excursions/the_platform/_1.jpg";
import Platform_2 from "../assets/images/excursions/the_platform/_2.jpg";
import Platform_3 from "../assets/images/excursions/the_platform/_3.jpg";
import Platform_4 from "../assets/images/excursions/the_platform/_4.jpg";
import Platform_5 from "../assets/images/excursions/the_platform/_5.jpg";
import Platform_6 from "../assets/images/excursions/the_platform/_6.jpg";


// Imisi
import Imisi_1 from "../assets/images/excursions/imisi/_1.jpg";
import Imisi_2 from "../assets/images/excursions/imisi/_2.jpg";
import Imisi_3 from "../assets/images/excursions/imisi/_3.jpg";


// The Platform
import Altschool_1 from "../assets/images/excursions/altschool/_1.jpg";
import Altschool_2 from "../assets/images/excursions/altschool/_2.jpg";
import Altschool_3 from "../assets/images/excursions/altschool/_3.jpg";
import Altschool_4 from "../assets/images/excursions/altschool/_4.jpg";


// Learners
import Learners_1 from "../assets/images/excursions/learners/_1.jpg";
import Learners_2 from "../assets/images/excursions/learners/_2.jpg";
import Learners_3 from "../assets/images/excursions/learners/_3.jpg";
import Learners_4 from "../assets/images/excursions/learners/_4.jpg";

// Relearn
import Relearner_1 from "../assets/images/excursions/relearn/_1.jpg";
import Relearner_2 from "../assets/images/excursions/relearn/_2.jpg";
import Relearner_3 from "../assets/images/excursions/relearn/_3.jpg";
import Relearner_4 from "../assets/images/excursions/relearn/_4.jpg";
// Imisi
// import _5 from "../assets/images/media2.png";
// import _6 from "../assets/images/students.jpg";
import PageLoader from "../components/page-loader";


const AboutPage = () => {
    return (
        <div className="about-page">
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    <h5>Excursions and events</h5>
                    <h2>Experience-Based Learning</h2>
                </div>
            </div>
            <div>
                <div className="container body-content">
                    <div>
                        <p>In line with our mission at Isador Model School (IMS) to prepare
                            young people for the real world, we actively seek opportunities to broaden our
                            students' exposure beyond their immediate environment. We firmly believe that a
                            child's aspirations are shaped by their experiences.</p>
                        <p>Excursions are integral to our school ethos; they're not confined to a singular event during the second term.
                            Instead, trips are a year-round endeavour. These excursions extend beyond company visits and site tours; at times,
                            our students actively participate or volunteer at various events.</p>
                        <p>Since our inception, our students have explored companies like Altschool Africa, Learners Corner, Imisi 3D, actively
                            participated in events such as National Youth Leadership Development Symposium, organised a TEDx event and attended
                            gatherings like The Platform and OSCAFEST.</p>

                        <div className="excursion-display">
                            <div>
                                <h5>Our Students hosting TedX</h5>
                                <div className="grid-4">
                                    <img src={TedX_1} alt="our students hosting a TedX Event" />
                                    <img src={TedX_2} alt="our students hosting a TedX Event" />
                                    <img src={TedX_3} alt="our students hosting a TedX Event" />
                                    <img src={TedX_4} alt="our students hosting a TedX Event" />
                                    <img src={TedX_5} alt="our students hosting a TedX Event" />
                                    <img src={TedX_6} alt="our students hosting a TedX Event" />
                                    <img src={TedX_7} alt="our students hosting a TedX Event" />
                                    <img src={TedX_8} alt="our students hosting a TedX Event" />
                                </div>
                            </div>
                            <div className="mt_5">
                                <h5>Our Students at The Platform</h5>
                                <div className="grid-4">
                                    <img src={Platform_1} alt="our students at The Platform" />
                                    <img src={Platform_2} alt="our students at The Platform" />
                                    <img src={Platform_3} alt="our students at The Platform" />
                                    <img src={Platform_4} alt="our students at The Platform" />
                                </div>
                            </div>
                            <div className="mt_5">
                                <h5>Our Students at The Imisi 3D</h5>
                                <div className="grid-4">
                                    <img src={Imisi_1} alt="our students at Imisi 3D" />
                                    <img src={Imisi_2} alt="our students at Imisi 3D" />
                                    <img src={Imisi_3} alt="our students at Imisi 3D" />
                                </div>
                            </div>
                            <div className="mt_5">
                                <h5>Our Students at AltSchool</h5>
                                <div className="grid-4">
                                    <img src={Altschool_1} alt="our students at Altschool" />
                                    <img src={Altschool_2} alt="our students at Altschool" />
                                    <img src={Altschool_3} alt="our students at Altschool" />
                                    <img src={Altschool_4} alt="our students at Altschool" />
                                </div>
                            </div>
                            <div className="mt_5">
                                <h5>Our Students at Learners</h5>
                                <div className="grid-4">
                                    <img src={Learners_1} alt="our students at Learners Africa" />
                                    <img src={Learners_2} alt="our students at Learners Africa" />
                                    <img src={Learners_3} alt="our students at Learners Africa" />
                                    <img src={Learners_4} alt="our students at Learners Africa" />
                                </div>
                            </div>
                            <div className="mt_5">
                                <h5>Our Students at Relearn</h5>
                                <div className="grid-4">
                                    <img src={Relearner_1} alt="our students at Relearn" />
                                    <img src={Relearner_2} alt="our students at Relearn" />
                                    <img src={Relearner_3} alt="our students at Relearn" />
                                    <img src={Relearner_4} alt="our students at Relearn" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutPage;