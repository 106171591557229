import "../assets/css/mini.css";

import React, { useState, useEffect } from "react";

// import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import axios from '../../utils/axiosCall';

import { Link } from "react-router-dom";

import locationMap from '../assets/images/mini/locationmap.jpg';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from '../components/footer';
import Nav from "../components/nav";
import PageLoader from "../components/page-loader";

const Contact = () => {
    const [loadingData, setLoadingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const signupValidator = yup.object().shape({
        emailAddress: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
        password: yup.string().required('Please enter your password'),
        firstName: yup.string().required('Please enter your first name'),
        lastName: yup.string().required('Please enter your last name')
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(signupValidator)
    });

    const signUpUser = e => {
        setLoadingData(true);
        setErrorMessage('');
        let { firstName, lastName, emailAddress, password } = e;
        // axios.post('/signup', {
        //     firstName, lastName, emailAddress, password
        // })
        //     .then(userData => {
        //         if (userData.data.statusMessage === "success") {
        //             window.location = `/signin`;
        //         } else {
        //             setLoadingData(false);
        //             setErrorMessage(userData.data.summary);
        //         }
        //     })
        //     .catch(err => {
        //         setLoadingData(false);
        //         setErrorMessage('An error occurred while saving data. Please try again.');
        //     })
    }
    return (
        <div className="about-page contact-page">
            <PageLoader />
            <Nav relativeNav={true} />
            <div className="abt-hero">
                <div className="contain">
                    <h5>CONTACT US</h5>
                    <h2>REACH OUT TO US</h2>
                </div>
            </div>
            <div className="contact-flex-display">
                <div className="black-bg">
                    <h3>You can reach us on:</h3>
                    <div className="black-bg-content">
                        <p className="contact-inner-lead">Contact Information:</p>
                        <div>
                            <p>For general inquiries, please call us on <a href="+2348169511139">0816 951 1139</a> or <a href="+2348169511139">0816 951 1139</a>
                            </p>
                            <p>For help on student portal issues, please send a mail to <span>admin@isadormodelschools.com</span></p>
                            <p className="mt_3">For help on new student registration, please send a mail to <span>registration@isadormodelschools.com</span></p>
                            <p className="mt_3">To see some of our pictures and see how we work, kindly visit our
                                Instagram page <span>@isadormodelschools</span></p>
                        </div>
                    </div>
                    <div className="black-bg-content">
                        <p className="contact-inner-lead">School Address:</p>
                        <p>4, Sanusi street, Council bus stop, Ikotun, Lagos State.</p>
                        <div className="sticky_social">
                            <div>
                                <div className="icon-cover">
                                    <ion-icon name="logo-facebook"></ion-icon>
                                </div>
                                <div className="icon-cover">
                                    <ion-icon name="logo-twitter"></ion-icon>
                                </div>
                                <div className="icon-cover">
                                    <ion-icon name="logo-instagram"></ion-icon>
                                </div>
                                <div className="icon-cover">
                                    <ion-icon name="logo-youtube"></ion-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-content-display">
                    <div className="contain">
                        <p>Please complete the necessary fields below. A representative from Isador Model School will respond promptly.</p>
                        <div>
                            <form onSubmit={handleSubmit(signUpUser)}>
                                <div className="form_flex">
                                    <div className="form-group space">
                                        <label htmlFor="firstName">First name</label>
                                        <Controller name="firstName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }} type="text" {...field}
                                                        name="firstName" />
                                                )
                                            }} />
                                        {errors.firstName && <p className="errorMessage">{errors.firstName.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last name</label>
                                        <Controller name="lastName" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }} type="text" {...field}
                                                        name="lastName" />
                                                )
                                            }} />
                                        {errors.lastName && <p className="errorMessage">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                                <div className="form_flex">
                                    <div className="form-group">
                                        <label htmlFor="emailAddress">Email address</label>
                                        <Controller name="emailAddress" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }} type="email" {...field}
                                                        name="emailAddress" />
                                                )
                                            }} />
                                        {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone number</label>
                                        <Controller name="phoneNumber" control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Input style={{ height: '5rem' }} type="email" {...field}
                                                        name="phoneNumber" />
                                                )
                                            }} />
                                        {errors.phoneNumber && <p className="errorMessage">{errors.phoneNumber.message}</p>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Message</label>
                                    <Controller name="password" control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input.TextArea type="password"
                                                    rows="5"
                                                    {...field}
                                                    name="password" />
                                            )
                                        }} />
                                    {errors.password && <p className="errorMessage">{errors.password.message}</p>}
                                </div>
                                {
                                    loadingData
                                        ?
                                        <button>
                                            <span style={{ marginRight: '10px' }}>Creating Account. Please wait...</span>
                                            <Spin indicator={antIcon} /></button>
                                        :
                                        <button className="btn-yellow">Send us a Message</button>
                                }
                                {/* <Divider orientation="left">Or</Divider>
                                <Link to="/signin"
                                    className="margin-bottom">Have an account already? Sign in here</Link> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noMargin={true} />
        </div>
    )
}

export default Contact;