import React, { useEffect, useState } from "react";

import Logo from "../assets/images/logo.png";
import { ReactComponent as MenuIcon } from "../assets/images/menu.svg";
import { ReactComponent as CancelIcon } from "../assets/images/x.svg";
import { Link, NavLink } from "react-router-dom";
import AppRoutes from "../utils/routes";
import { Drawer } from "antd";
import AllAppRoutes from "../utils/routes";

const Nav = props => {
    const [fixedNav, setFixedNav] = useState(false);
    const [openMenuDrawer, setOpenMenuDrawer] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 100) {
            setFixedNav(true);
        } else {
            setFixedNav(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const showDrawer = () => {
        setOpenMenuDrawer(true);
    };
    const onClose = () => {
        setOpenMenuDrawer(false);
    };
    return (
        <div>
            <div className={`navigation ${props.relativeNav ? 'relativeNav' : ''} ${fixedNav ? 'fix' : ''}`}>
                <div className="inner-nav">
                    <Link to="/" className="logo-link">
                        <div className="logo-bar">
                            <img src={Logo} alt="logo" />
                            <h3>Isador Model <span>Schools</span></h3>
                        </div>
                    </Link>
                </div>
                <div className="desktop-only">
                    <ul>
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.about_us}>About Us</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.blog}>Our Blog</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.excursion}>Excursion</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.approach}>Our Approach to Education</NavLink>
                        </li>
                        <li>
                            <a href="https://youtube.com/@sabibook?si=bwpKPMvjUr_vzQlM" target="_blank">Youtube</a>
                        </li>
                    </ul>
                </div>
                <div className="desktop-only">
                    <ul>
                        {/* <li>
                            <NavLink className={({isActive}) => isActive ? "active" : ""} to={AppRoutes.signin}>(+234) 813 227 7316</NavLink>
                        </li> */}
                        <li>
                            <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.contact_us}>Contact Us</NavLink>
                        </li>
                        <li className="colored">
                            <Link to={AppRoutes.sign_in}>Student Portal</Link>
                        </li>
                    </ul>
                </div>
                <div className="mobile-only">
                    <div onClick={() => setOpenMenuDrawer(true)}>
                        <MenuIcon />
                    </div>
                </div>
            </div>
            <Drawer title={null} placement="right" onClose={onClose} open={openMenuDrawer}>
                <div className="mobile-nav">
                    <div className="mobile-header">
                        <div>

                        </div>
                        <div>
                            <div onClick={() => setOpenMenuDrawer(false)}>
                                <CancelIcon />
                            </div>
                        </div>
                    </div>
                    <div className="mobile-list">
                        <ul>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AllAppRoutes.about_us}>About Us</NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.blog}>Our Blog</NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AllAppRoutes.contact_us}>Contact Us</NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AllAppRoutes.excursion}>Excursions</NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AppRoutes.approach}>Our Approach to Education</NavLink>
                            </li>
                            <li>
                                <a href="https://youtube.com/@sabibook?si=bwpKPMvjUr_vzQlM" target="_blank">Youtube</a>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to={AllAppRoutes.sign_in}>Student Portal</NavLink>
                            </li>
                            {/* <li>
                                <NavLink className={({ isActive }) => isActive ? "active" : ""} to="">Frequently Asked Questions</NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Nav;