import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Facebook } from "../assets/images/facebook.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as LinkedIn } from "../assets/images/linkedin.svg";
import { ReactComponent as Instagram } from "../assets/images/instagram.svg";

const Footer = props => {
    return (
        <div className={`footer ${props.margin ? 'margin' : ''} ${props.noMargin ? 'noMargin' : ''}`}>
            <div className="contain">
                <div className="footer-grid-2">
                    <div>
                        <h4>CONTACT US</h4>
                        <ul className="contact-list">
                            <li>
                                <a href="tel:+2348136181285">+234 813 618 1285</a>
                            </li>
                            <li className="sec">
                                <Link to="">|</Link>
                            </li>
                            <li>
                                <a href="tel:+2348136181285">+234 813 618 1285</a>
                            </li>
                            <li className="sec">
                                <Link to="">|</Link>
                            </li>
                            <li>
                                <a href="mailTo:help@isadormodelschools.com">help@isadormodelschools.com</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <Link to="">About Us</Link>
                            </li>
                            <li>
                                <Link to="">Our Excursions</Link>
                            </li>
                            <li>
                                <Link to="">Our Approach to Education</Link>
                            </li>
                            <li>
                                <Link to="">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="">Student Portal</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-grid-2 sec-2">
                    <div>
                        <p className="ims-story">Our goal isn’t to merely be just another secondary school; we aim to pioneer a model institution that challenges
                            the norms of traditional education to better equip students for the real world. The deliberate choice of the
                            term 'model school' reflects our intention to create a globally replicable educational system. The model school represents just a
                            fraction of our broader vision, aimed at making a positive impact on our local community and the African continent as a whole.</p>
                        {/* <p className="ims-story">At IGS, we acknowledge the First Nations People of Australia as the strong, resilient and
                            resourceful custodians who have passed on their cultures, stories and songs for
                            generations always connected to Country and nurturing the land, seas and skies. We
                            pay our respects to the Gadigal People of the Eora Nation on whose lands IGS stands
                            and where we strive to value the perspectives of the oldest surviving culture in the
                            world. This land was, is, and always will be Aboriginal land.</p> */}
                    </div>
                    <div>
                        <div className="icon-showcase">
                            <div className="social-icon"><Facebook /></div>
                            <div className="social-icon"><Instagram /></div>
                            <div className="social-icon"><LinkedIn /></div>
                            <div className="social-icon"><Twitter /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sec-footer">
                <div className="contain">
                    <div className="footer-grid-2">
                        <div>
                            <p>©2023 All Rights Reserved.</p>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link to="">Accessibility</Link>
                                </li>
                                <li>
                                    <Link to="">|</Link>
                                </li>
                                <li>
                                    <Link to="">Consumer Information</Link>
                                </li>
                                <li>
                                    <Link to="">|</Link>
                                </li>
                                <li>
                                    <Link to="">Privacy & Security</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;