import React, { useLayoutEffect, useRef } from "react";
import { gsap, Power3 } from "gsap";

const PageLoader = () => {
    const pageCover = useRef();
    useLayoutEffect(() => {
        gsap.to(pageCover.current, {
            borderBottomLeftRadius: "300%",
            borderBottomRightRadius: "300%",
            height: 0,
            duration: 3,
            ease: Power3.easeInOut,
            yoyo: true
        })
    }, [])
    return (
        <div className="first-opening" ref={pageCover}>
            <div className="first-opening-cover">
            </div>
        </div>
    )
}

export default PageLoader;