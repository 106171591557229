import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";
import PageLoader from "../components/page-loader";

const VacanciesPage = () => {
    return (
        <div className="about-page">
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    <h5>WORK WITH US</h5>
                    <h2>JOB VACANCIES</h2>
                </div>
            </div>
            <div>
                <div className="container body-content">
                    <div className="center-empty-element">
                        <p>We have no open vacancies at the moment. Be sure to check back later</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default VacanciesPage;