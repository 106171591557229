import React from "react";

import Nav from "../components/nav";
import Footer from "../components/footer";
import PageLoader from "../components/page-loader";

const CalendarPage = () => {
    return (
        <div className="about-page calendar">
            <PageLoader />
            <Nav />
            <div className="abt-hero">
                <div className="contain">
                    <h5>OUR CALENDAR</h5>
                    <h2>HAPPENINGS AT SCHOOL</h2>
                </div>
            </div>
            <div>
                <div className="container body-content">
                    <div>

                        <p>IMS students are expected to conduct themselves at all times in a
                            manner that demonstrates respect for the School, fellow students and
                            its staff. Students are ambassadors for the School, and are expected
                            to behave in a way that enhances the reputation of the School.</p>
                    </div>
                    <div>
                        <h3>UPCOMING EVENTS</h3>
                        <div className="grid-2">
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                            <div className="event-block">
                                <div className="date-block">
                                    <h3>18 January</h3>
                                </div>
                                <div>
                                    <h4>Christmas break</h4>
                                    <p>IMS students are expected to conduct themselves at all times in a
                                        manner that demonstrates respect for the School, fellow students and
                                        its staff.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CalendarPage;